// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'

import LayoutMain from '../../../../layout'
import { BeesPayReportContainerByDayTickets } from '../../../../containers/BeesPayReportContainerByDayTickets'

const DetailsByDayTickets: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="BeesPay Dias" />
      <WithPrivateRoute component={BeesPayReportContainerByDayTickets} />
    </LayoutMain>
  )
}

export default DetailsByDayTickets
