import React, { useEffect, useState } from 'react'
import { getParameter } from '../../utils/queryString'
import { transformQueryBeesReportToDataComponents } from './utils'

import BeespayReportContent from '../../components/BeespayReportContent'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/client'
import { GET_TRX_LIST } from '../../graphql/queries/Reports/beespay'
import { TileRowsProps } from '../../components/Storybook/TileRows'
import { TableGridProps } from '../../components/Storybook/TableGrid'
import moment from 'moment'
import Loading from '../../components/Shared/Loading'

export const BeesPayReportContainerByDayTickets: React.FC = () => {
  const storeId = getParameter('storeId') ? getParameter('storeId') : ''
  const from = getParameter('from') ? getParameter('from') : ''
  const fromDate = moment(from).format('DD/MM/yyyy')
  const loading = false
  const [loadingContainer, setLoadingContainer] = useState(true)
  const [tileRow, setTileRow] = useState<TileRowsProps>({ rowBody: [] })
  const [tableGrid, setTableGrid] = useState<TableGridProps>({
    data: [],
    columns: []
  })

  const { data: detailDayData, loading: detailDayLoading } = useQuery(
    GET_TRX_LIST,
    {
      variables: {
        from: fromDate,
        storeID: storeId
      }
    }
  )

  useEffect(() => {
    if (detailDayData && !detailDayLoading) {
      const { tileRow: tileRowTransformed, tableGrid: tableGridTransformed } =
        transformQueryBeesReportToDataComponents(detailDayData)
      setTileRow(tileRowTransformed)
      setTableGrid(tableGridTransformed)
      setLoadingContainer(false)
    }
  }, [detailDayData, detailDayLoading])

  const onClickBack = () => {
    navigate(`/reports/beespay?storeId=${storeId}`)
  }

  return (
    <>
      {!detailDayLoading && !loadingContainer && (
        <BeespayReportContent
          tileRow={tileRow}
          tableGrid={tableGrid}
          view="day"
          storeID={storeId ? storeId : ''}
          loading={loading}
          from={from}
          onClickBack={onClickBack}
        />
      )}
      {detailDayLoading && <Loading />}
    </>
  )
}
