import styled from 'styled-components'

export const StyledPopup = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(16, 53, 110, 0.89);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
`
export const Content = styled.div`
  height: fit-content;
  width: 277px;
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 0.5em;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  padding: 47px 0;

  @media (min-width: 768px) {
    width: 623px;
  }
`
export const Title = styled.p`
  color: #e01923;
  text-align: center;
  font-weight: bold;
`

export const ButtonWrap = styled.div`
  padding-top: 15px;
  margin: 0 auto;
  width: 50%;
`
export const Text = styled.p`
  text-align: center;
  font-weight: bold;
`
