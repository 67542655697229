// Package
import styled from 'styled-components'

export const StyledOperationsTypeReportContainer = styled.div`
  height: 100vh;
`

export const ReportContentHeader = styled.div`
  width: 100%;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: left;
  align-items: left;
  background-color: white;
  height: 60px;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 5%);
  :hover {
    cursor: pointer;
  }
`

export const StyledReportTitle = styled.h1`
  margin: 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b4793;
`
export const ReportContentTotals = styled.div`
  padding: 2%;
`

export const StyledTotalsTitle = styled.h1`
  margin: 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b4793;
`

export const TotalContent = styled.div`
  padding: 10px;
  background-color: white;
`

export const ResumeContent = styled.div`
  display: inline-block;
  margin-left: 10px;
  h2 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    color: #828282;
  }

  @media all and (max-width: 480px) {
    font-size: 11px;
    p {
      font-size: 11px;
    }
  }
`
