import React from 'react'
import { StyledButtonPrimary } from '../Shared/Button/styledComponent'
import {
  StyledPopup,
  Content,
  Title,
  Text,
  ButtonWrap
} from './styledComponents'

export interface SimplePopupProps {
  title?: string
  text?: string
  buttonText?: string
  buttonAction?: () => void
}

const SimplePopup: React.FC<SimplePopupProps> = (simplePopupProps) => (
  <StyledPopup>
    <Content>
      <Title>{simplePopupProps.title}</Title>
      <Text>{simplePopupProps.text}</Text>
      <ButtonWrap>
        <StyledButtonPrimary
          data-testid="button-aceptar"
          type="button"
          disabled={false}
          onClick={simplePopupProps.buttonAction}
        >
          {simplePopupProps.buttonText}
        </StyledButtonPrimary>
      </ButtonWrap>
    </Content>
  </StyledPopup>
)

export default SimplePopup
