import { Row } from 'antd'
import styled from 'styled-components'

export const StyledTGContainer = styled.div`
  padding: 2%;
  margin: 1% auto;
  background-color: #ffffff;
  width: 100%;

  @media all and (max-width: 480px) {
    margin: 5% auto;
  }
`

export const StyledTGHeader = styled.div`
  font-weight: bold;
  background-color: grey;
`
export const StyledTGBody = styled.div`
  font-size: 11px;
  font-weight: 600;
`

export const StyledTGRow = styled.div`
  padding: 4px 4px;
`
export const StyledTGContainerHr = styled.hr`
  background-color: #d2d2d2;
  opacity: 30%;
`
export const StyledTGContainerHrVert = styled.div`
  border-left: 1px solid #d2d2d2;
  height: 20px;
  position: absolute;
  opacity: 30%;
  margin-top: 10px;
`

export const StyledRowPadding = styled(Row)`
  padding: 8px 0px;
  text-align: center;
  height: 100%;
`
