import { Row, Col } from 'antd'
import React, { Fragment } from 'react'
import {
  StyledRowPadding,
  StyledTGBody,
  StyledTGContainer,
  StyledTGContainerHr,
  StyledTGContainerHrVert,
  StyledTGHeader,
  StyledTGRow
} from './styledComponents'

export interface TGColumnProp {
  dataIndex: string
  title: string
  render?: (object: any, row: any) => React.ReactNode
}

export interface TGDataProps {
  key: string
  [keys: string]: React.ReactNode
}

export interface TGContainerColumnsProps {
  columns: TGColumnProp[]
  row: TGDataProps
  indexRow: number
  isVerticalLine?: boolean
}

export interface TableGridProps {
  data: TGDataProps[]
  columns: TGColumnProp[]
  isHeader?: boolean
  isVerticalLine?: boolean
}

export interface TGColumnProps {
  index: number
  render: React.ReactNode
  isVerticalLine?: boolean
}

const TGColumn: React.FC<TGColumnProps> = ({
  index,
  render,
  isVerticalLine
}) => (
  <StyledRowPadding justify="space-between" style={{ padding: '8px 0px' }}>
    <Col span={2}>
      {index !== 0 && isVerticalLine && <StyledTGContainerHrVert />}
    </Col>

    <Col span={22}>{render}</Col>
  </StyledRowPadding>
)

const TGContainerColumns: React.FC<TGContainerColumnsProps> = ({
  columns,
  row,
  indexRow,
  isVerticalLine = true
}) => (
  <Row justify="space-between">
    {columns.map((col, index) => {
      const { dataIndex, render } = col
      const columnInRow = row[dataIndex]
      const renderColumnInRow = render ? render(columnInRow, row) : columnInRow
      return (
        <Fragment key={`Column${indexRow}_${row.key}_${dataIndex}`}>
          <Col span={24 / columns.length}>
            <TGColumn
              index={index}
              render={renderColumnInRow}
              isVerticalLine={isVerticalLine}
            />
          </Col>
        </Fragment>
      )
    })}
  </Row>
)

const TableBody: React.FC<TableGridProps> = ({
  data,
  columns,
  isVerticalLine
}) => (
  <StyledTGBody>
    {data.map((row, index) => (
      <StyledTGRow key={`Row_${index}`}>
        {index !== 0 && <StyledTGContainerHr />}
        <TGContainerColumns
          columns={columns}
          row={row}
          indexRow={index}
          isVerticalLine={isVerticalLine}
        />
      </StyledTGRow>
    ))}
  </StyledTGBody>
)

const TableHeader: React.FC<TableGridProps> = ({
  columns,
  isHeader = true,
  isVerticalLine = true
}) => {
  return isHeader ? (
    <StyledTGHeader>
      <Row justify="space-between">
        {columns.map((col, index) => (
          <Fragment key={`Header${index}_${col.dataIndex}`}>
            <Col span={24 / columns.length}>
              <TGColumn
                index={index}
                render={col.title}
                isVerticalLine={isVerticalLine}
              />
            </Col>
          </Fragment>
        ))}
      </Row>
    </StyledTGHeader>
  ) : null
}

const TableGrid: React.FC<TableGridProps> = (tableGridProps) => {
  return (
    <StyledTGContainer>
      <TableHeader {...tableGridProps} />
      <TableBody {...tableGridProps} />
    </StyledTGContainer>
  )
}

export default TableGrid
