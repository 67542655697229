import React, { Fragment } from 'react'
import TileRows, { TileRowsProps } from '../Storybook/TileRows'
import TableGrid, { TableGridProps } from '../Storybook/TableGrid'
import SimplePopup, { SimplePopupProps } from '../SimplePopup'
import { Row } from 'antd'
import {
  StyledEmptyData,
  StyledReportContent
} from '../ReportContent/styledComponents'
// import { navigate } from 'gatsby'
import { isEmpty } from 'lodash'
import IconComponent from '../Icon'
import EmptyImg from '../../data/assets/icons/reports-data-empty.svg'
import Loading from '../Shared/Loading'
import { ReportContentHeader } from '../../containers/TotalOperationsContainer/styledComponents'
import CustomButton from '../Storybook/customButton'
import { LeftOutlined } from '@ant-design/icons'
import { StyledReportTitle } from '../../containers/FilterSalesReportContainer/styledComponents'

export interface ReportContentProps {
  storeID: string
  tileRow: TileRowsProps
  tableGrid: TableGridProps
  loading?: boolean
  simplePopupProps?: SimplePopupProps
  showNoResultsPopup?: boolean
  setShowNoResultsPopup?: () => void
  view: string
  onClickDownloadPDF?: () => void
  onClickDownloadCSV?: () => void
  disabledDownload?: boolean
  from?: string
  onClickBack: () => void
}

const BeespayReportContent: React.FC<ReportContentProps> = ({
  tileRow,
  tableGrid,
  loading,
  simplePopupProps,
  showNoResultsPopup,
  onClickBack
}) => {
  const body = (
    <Fragment>
      <Row gutter={16}>
        <ReportContentHeader>
          <CustomButton
            id="button-icon-back-sales-report"
            name="buttonIconBackSalesReport"
            className="button-icon-back-sales-report"
            dataTestId="data-test-button-icon-back-sales-report"
            icon={<LeftOutlined />}
            onClick={onClickBack}
          />
          <StyledReportTitle>Reporte BEES Pay</StyledReportTitle>
        </ReportContentHeader>
      </Row>
      <TileRows {...tileRow} />
      {!isEmpty(tableGrid.data) && <TableGrid {...tableGrid} />}
      {showNoResultsPopup && <SimplePopup {...simplePopupProps} />}
      {isEmpty(tableGrid.data) && (
        <StyledEmptyData>
          <IconComponent icon={EmptyImg} width={151} height={126} />
          <p>No hay ventas registradas, intenta con otros filtros</p>
        </StyledEmptyData>
      )}
    </Fragment>
  )

  return (
    <StyledReportContent>
      {loading ? <Loading /> : tileRow.rowBody.length > 0 && body}
    </StyledReportContent>
  )
}

export default BeespayReportContent
